import { Route } from '@angular/router';
import { CONNECTED_ROUTE } from '@core/routes/connected.routes';
import { ERROR_ROUTES } from '@core/routes/error.routes';
import { UNCONNECTED_ROUTES } from '@core/routes/unconnected.routes';
import { FEATURES_ROUTING } from '@features/features.routing';

export interface ObendyRoute extends Route {
  showInSiteMap: boolean;
  children?: ObendyRoutes;
  loadChildren?: () => ObendyRoutes;
}

export type ObendyRoutes = ObendyRoute[];

export const AppRoutes: ObendyRoutes = [
  ...UNCONNECTED_ROUTES,
  CONNECTED_ROUTE,
  ...ERROR_ROUTES,

  {
    path: '**',
    redirectTo: `/${FEATURES_ROUTING.notFound}`,
    pathMatch: 'full',
    showInSiteMap: false,
  },
];
