import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { ApiTraining, Training, TrainingFunding } from '@models/training/training';
import { TrainingWebservice } from '@webservices/trainings/trainings.webservice';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { Observable, map } from 'rxjs';

export const trainingResolver: ResolveFn<Observable<Training>> = (route) => {
  const trainingWebservice = inject(TrainingWebservice);
  const router = inject(Router);

  return trainingWebservice
    .get(route.paramMap.get('trainingId') ?? '')
    .pipe(catchNotFound(router, ['404']), map(mapApiTrainingToTraining));
};

const mapApiTrainingToTraining = (apiTraining: ApiTraining): Training => {
  const {
    id,
    title,
    sessions,
    provider,
    contact,
    objectives,
    description,
    conditions,
    results,
    additionalInfos,
    pace,
    fundings,
    isApprenticeship,
    isProfessionalisation,
    isCertifying,
  } = apiTraining;
  return {
    id,
    title,
    sessions,
    provider,
    contact,
    chips: getTrainingChips(fundings, { isApprenticeship, isProfessionalisation, isCertifying }),
    trainingDescription: {
      objectives,
      description,
      conditions,
      results,
      trainingRegionCode: parseInt(contact.formationAddress.regionCode),
      additionalInfos,
      pace,
      fundings,
    },
  };
};

const getTrainingChips = (
  fundings: TrainingFunding[],
  {
    isApprenticeship,
    isProfessionalisation,
    isCertifying,
  }: { isApprenticeship: boolean; isProfessionalisation: boolean; isCertifying: boolean }
): { title: string; tooltip?: string }[] => {
  const chips: { title: string; tooltip?: string }[] = fundings.map((funding) => ({
    title: funding.shortLabel,
    tooltip: funding.label,
  }));
  if (isApprenticeship) {
    chips.push({ title: 'Apprentissage' });
  }
  if (isProfessionalisation) {
    chips.push({ title: 'Professionnalisation' });
  }
  if (isCertifying) {
    chips.push({ title: 'Certifiante' });
  }

  return chips;
};
