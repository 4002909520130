import { ObendyRoute } from '@core/app-routing';
import { hasLoginTokenGuard } from '@core/guards/has-login-token.guard';
import { isLoggedGuard } from '@core/guards/is-logged.guard';
import { isNotInternalService } from '@core/guards/is-not-internal-service.guard';
import { shouldFinishOnboardingGuard } from '@core/guards/should-finish-onboarding.guard';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { FEATURES_ROUTING } from '@features/features.routing';

export const REDIRECTION_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.redirect,
  title: 'Redirection',
  canActivate: [hasLoginTokenGuard, isLoggedGuard, shouldFinishOnboardingGuard],
  showInSiteMap: false,
  loadChildren: () => [
    {
      path: `:${SERVICE_ID_ROUTE_PARAM}`,
      canActivate: [isNotInternalService],
      showInSiteMap: false,
      loadComponent: () => import('@features/redirect/redirect.component').then((cmp) => cmp.RedirectComponent),
      data: {
        showHeader: false,
        showFooter: false,
      },
    },
  ],
};
