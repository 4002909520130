import { inject } from '@angular/core';
import { ObendyRoute } from '@core/app-routing';
import { hasLoginTokenGuard } from '@core/guards/has-login-token.guard';
import { isLoggedGuard } from '@core/guards/is-logged.guard';
import { isNotGuardianGuard } from '@core/guards/is-not-guardian.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { BankingProductsWebservice } from '@webservices/banking-products.webservice';
import { filter, switchMap } from 'rxjs';

export const BANKING_PRODUCTS_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.bankingProducts,
  title: 'Nos offres',
  showInSiteMap: true,
  loadComponent: () =>
    import('@features/banking-products/banking-products.component').then((cmp) => cmp.BankingProductsComponent),
  canActivate: [hasLoginTokenGuard, isLoggedGuard, isNotGuardianGuard],
  resolve: {
    bankingProductsConfig: () => {
      const bankingProductsWebservice = inject(BankingProductsWebservice);
      return inject(Store)
        .select(partnerId)
        .pipe(
          filter((partnerId): partnerId is string => !!partnerId),
          switchMap((partnerId) => bankingProductsWebservice.getBankingProductPage(partnerId))
        );
    },
  },
};
