import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { isLegalGuardian } from '@stores/profile/profile.selectors';
import { isLogged } from '@wizbii-utils/angular/stores';
import { filter, iif, of, switchMap } from 'rxjs';

export const LegalGuardianMatcher: CanMatchFn = () => {
  const store = inject(Store);
  return store.select(isLogged).pipe(
    switchMap((isLogged) => iif(() => isLogged, store.select(isLegalGuardian), of('/'))),
    filter((isLegalGuardian): isLegalGuardian is boolean => isLegalGuardian !== null)
  );
};
