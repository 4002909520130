import { ObendyRoute } from '@core/app-routing';
import { hasLoginTokenGuard } from '@core/guards/has-login-token.guard';
import { platformHasPaymentGuard } from '@core/guards/platform-has-payment.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { LEGAL_PAGES_ROUTING } from '@features/legal-pages/legal-pages.routing';
import { legalPageCanActivate } from '@features/legal-pages/legal-pages/legal-page.guard';
import { legalPageResolver } from '@features/legal-pages/legal-pages/legal-page.resolver';

const reuseTime = 3600; //1 hour

export const LEGAL_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.legalPages,
  canActivate: [hasLoginTokenGuard],
  title: 'Mentions légales',
  showInSiteMap: true,

  loadComponent: () =>
    import('@features/legal-pages/legal-pages-layout/legal-pages-layout.component').then(
      (cmp) => cmp.LegalPagesLayoutComponent
    ),

  data: {
    shouldReuse: true,
    reuseTime,
  },

  loadChildren: () => [
    {
      path: LEGAL_PAGES_ROUTING.cgv,
      title: 'CGV',
      canActivate: [platformHasPaymentGuard, legalPageCanActivate],
      loadComponent: () =>
        import('@features/legal-pages/legal-pages/legal-pages.component').then((cmp) => cmp.LegalPagesComponent),
      showInSiteMap: true,
      resolve: {
        legalPage: legalPageResolver,
      },
      data: {
        type: 'cgv',
        guardRouteToRedirect: `/${FEATURES_ROUTING.notFound}`,
        reuseTime,
      },
    },

    {
      path: ':type',
      loadComponent: () =>
        import('@features/legal-pages/legal-pages/legal-pages.component').then((cmp) => cmp.LegalPagesComponent),
      canActivate: [legalPageCanActivate],
      showInSiteMap: true,
      resolve: {
        legalPage: legalPageResolver,
      },
      data: {
        titlePath: 'legalPage.title',
        shouldReuse: true,
        reuseTime,
      },
    },

    {
      path: '',
      redirectTo: LEGAL_PAGES_ROUTING.mentions,
      pathMatch: 'full',
      showInSiteMap: true,
    },
  ],
};
