import { inject } from '@angular/core';
import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { getEventFullMiddlewareMatch } from '@core/middlewares/get-event-full.middleware';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { EVENT_ROUTING } from '@features/services/event-full/event-full.routing';
import { isEventFullMatcherGuard } from '@features/services/event-full/guards/is-event-full-matcher.guard';
import { isWizbiiEventFullMatcherGuard } from '@features/services/event-full/guards/is-wizbii-event-full-matcher.guard';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { selectEventFull } from '@stores/event-full/event-full.selectors';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';

export const WIZBII_EVENT_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.eventFull,
  title: serviceTitleResolver,
  showInSiteMap: false,
  data: {
    serviceId: INTERNAL_SERVICES_IDS.eventFull,
  },
  canActivate: [updateServiceConfigurationMiddleware],

  loadChildren: () => [
    {
      path: EVENT_ROUTING.events,
      showInSiteMap: false,
      loadComponent: () =>
        import('@features/services/event-full/event-full.component').then((cmp) => cmp.EventFullComponent),
    },
    {
      path: `${EVENT_ROUTING.events}/${EVENT_ROUTING.eventsSlug}`,
      showInSiteMap: false,
      canMatch: [getEventFullMiddlewareMatch],
      resolve: {
        eventFull: () => inject(Store).select(selectEventFull),
      },
      data: {
        titlePath: 'eventFull.title',
      },
      children: [
        {
          path: '',
          showInSiteMap: false,
          loadComponent: () =>
            import('@features/services/event-full/wizbii-event-view/wizbii-event-view.component').then(
              (cmp) => cmp.WizbiiEventViewComponent
            ),
          canActivate: [requestServiceAuthorizationToRedirectGuard()],
          canMatch: [isWizbiiEventFullMatcherGuard],
        },
        {
          path: '',
          showInSiteMap: false,
          canActivate: [requestServiceAuthorizationToRedirectGuard()],
          loadComponent: () =>
            import('@features/services/event-full/event-full-view/event-full-view.component').then(
              (cmp) => cmp.EventFullViewComponent
            ),
          canMatch: [isEventFullMatcherGuard],
        },
      ],
    },

    {
      path: EVENT_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },

    {
      path: '',
      showInSiteMap: false,
      redirectTo: EVENT_ROUTING.events,
      pathMatch: 'full',
    },
  ],
};
