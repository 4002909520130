import { hasLoginTokenGuard } from '@core/guards/has-login-token.guard';
import { isLoggedGuard } from '@core/guards/is-logged.guard';
import { profileInformationsGuard } from '@core/guards/profile-information.guard';
import { shouldFinishOnboardingGuard } from '@core/guards/should-finish-onboarding.guard';

export const ConnectedRoutesGuards = [
  hasLoginTokenGuard,
  isLoggedGuard,
  shouldFinishOnboardingGuard,
  profileInformationsGuard,
];
