import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { canEditDossierGuard } from '@core/guards/wizbii-money/can-edit-dossier.guard';
import { canUpdateDossierGuard } from '@core/guards/wizbii-money/can-update-dossier.guard';
import { canViewDossierGuard } from '@core/guards/wizbii-money/can-view-dossier.guard';
import { dossierCompletionGuard } from '@core/guards/wizbii-money/dossier-completion.guard';
import { dossierValidationGuard } from '@core/guards/wizbii-money/dossier-validation.guard';
import { hasAccessToMoney, hasNoAccessToMoney } from '@core/guards/wizbii-money/has-access.guard';
import { hasStartedSimulationGuard } from '@core/guards/wizbii-money/has-started-simulation.guard';
import { loadNextDossierToUpdateGuard } from '@core/guards/wizbii-money/load-next-dossier-to-update.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { WIZBII_MONEY_PROVIDERS } from '@core/routes/connected-routes/services-routes/wizbii-money.providers';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { MONEY_PLAYER_CONFIG } from '@core/utils/money-player.config';
import { COMPLEMENTARY_PLAYER_ROUTING } from '@domains/money/complementary-player/complementary-player.routing';
import { ELIGIBILITY_PLAYER_ROUTING } from '@domains/money/eligibility-player/eligibility-player.routing';
import { MONEY_DASHBOARD_ROUTING } from '@domains/money/money-dashboard/money-dashboard.routing';
import {
  strapiBlogAdvicesResolver,
  strapiBlogHelpResolver,
} from '@domains/services/blog/resolvers/article/blog-article.resolver';
import { environment } from '@environment';
import { FEATURES_ROUTING } from '@features/features.routing';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { MONEY_ROUTING } from '@features/services/money/money.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';
import { moneyComplementaryResponsesResolver } from '@webservices/money/money-complementary-responses.resolver';

export enum MoneyRoutingTitlesEnum {
  Simulation = 'Ma Simulation',
  MyInfos = 'Mes infos',
  Tips = 'Conseils',
  Helps = 'Toutes les aides',
  Eligibility = 'Éligibilité',
}

export const WIZBII_MONEY_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.money,
  showInSiteMap: false,
  title: serviceTitleResolver,
  canActivate: [updateServiceConfigurationMiddleware],
  data: {
    serviceId: INTERNAL_SERVICES_IDS.money,
    algoliaIndex: environment.algolia.index.wizbiiMoneyHelps,
  },
  providers: [
    {
      provide: MONEY_PLAYER_CONFIG,
      useValue: {
        title: '5 min pour + de 400 aides',
        image: `${environment.wizbiiImages}/services/illustrations/wizbii-money-white-logo.svg`,
      },
    },
  ],

  loadChildren: () => [
    {
      path: '',
      showInSiteMap: false,
      providers: WIZBII_MONEY_PROVIDERS,

      children: [
        // WITH LAYOUT
        {
          path: '',
          showInSiteMap: false,
          loadComponent: () =>
            import('@domains/money/money-dashboard/money-layout/money-layout.component').then(
              (cmp) => cmp.MoneyLayoutComponent
            ),

          loadChildren: () => [
            // SIMULATION
            {
              path: MONEY_DASHBOARD_ROUTING.simulation,
              showInSiteMap: false,
              title: MoneyRoutingTitlesEnum.Simulation,

              loadChildren: () => [
                // HOMEPAGE
                {
                  path: '',
                  showInSiteMap: false,
                  canMatch: [hasAccessToMoney],
                  loadComponent: () =>
                    import('@domains/money/money-dashboard/money-dashboard/money-dashboard.component').then(
                      (cmp) => cmp.MoneyDashboardComponent
                    ),
                },
                {
                  path: '',
                  showInSiteMap: false,
                  canMatch: [hasNoAccessToMoney],
                  loadComponent: () =>
                    import(
                      '@domains/money/money-dashboard/money-dashboard-paywall/money-dashboard-paywall.component'
                    ).then((cmp) => cmp.MoneyDashboardPaywallComponent),
                },
              ],
            },

            // MY INFOS
            {
              path: MONEY_DASHBOARD_ROUTING.myInfos,
              canMatch: [hasAccessToMoney],
              showInSiteMap: false,
              title: MoneyRoutingTitlesEnum.MyInfos,
              loadComponent: () =>
                import('@domains/money/money-dashboard/my-infos/my-infos.component').then(
                  (cmp) => cmp.MyInfosComponent
                ),
            },
            {
              path: MONEY_DASHBOARD_ROUTING.myInfos,
              showInSiteMap: false,
              canMatch: [hasNoAccessToMoney],
              title: MoneyRoutingTitlesEnum.MyInfos,
              loadComponent: () =>
                import('@domains/money/money-dashboard/my-infos-paywall/my-infos-paywall.component').then(
                  (cmp) => cmp.MyInfosPaywallComponent
                ),
            },

            // TIPS
            {
              path: MONEY_DASHBOARD_ROUTING.tips,
              title: MoneyRoutingTitlesEnum.Tips,
              showInSiteMap: false,
              loadComponent: () =>
                import('@domains/money/money-dashboard/tips/tip-list/tip-list.component').then(
                  (cmp) => cmp.TipListComponent
                ),
            },

            // HELPS
            {
              path: MONEY_DASHBOARD_ROUTING.helps,
              showInSiteMap: false,
              title: MoneyRoutingTitlesEnum.Helps,
              canActivate: [hasStartedSimulationGuard],
              loadComponent: () =>
                import('@domains/money/money-dashboard/help-list/help-list.component').then(
                  (cmp) => cmp.HelpListComponent
                ),
            },

            {
              path: '',
              showInSiteMap: false,
              pathMatch: 'full',
              redirectTo: MONEY_DASHBOARD_ROUTING.helps,
            },
          ],
        },

        // WITHOUT LAYOUT
        {
          path: '',
          showInSiteMap: false,

          loadChildren: () => [
            // SIMULATION
            {
              path: MONEY_DASHBOARD_ROUTING.simulation,
              title: MoneyRoutingTitlesEnum.Simulation,
              showInSiteMap: false,
              canActivate: [requestServiceAuthorizationToRedirectGuard()],

              loadChildren: () => [
                // ELIGIBILITY PLAYER
                {
                  path: MONEY_ROUTING.eligibilityPlayer,
                  title: MoneyRoutingTitlesEnum.Eligibility,
                  showInSiteMap: false,
                  data: { showFooter: false },

                  loadChildren: () => [
                    {
                      path: '',
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/eligibility-player/eligibility-player/eligibility-player.component'
                        ).then((cmp) => cmp.EligibilityPlayerComponent),
                    },
                    {
                      path: ELIGIBILITY_PLAYER_ROUTING.ending,
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/eligibility-player/eligibility-ending/eligibility-ending.component'
                        ).then((cmp) => cmp.EligibilityEndingComponent),
                    },
                    {
                      path: `:${ELIGIBILITY_PLAYER_ROUTING.playerQuestion}`,
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/eligibility-player/eligibility-player/eligibility-player.component'
                        ).then((cmp) => cmp.EligibilityPlayerComponent),
                    },
                  ],
                },

                // COMPLEMENTARY PLAYER
                {
                  path: MONEY_ROUTING.complementaryPlayer,
                  showInSiteMap: false,

                  loadChildren: () => [
                    {
                      path: ':suggestionId',
                      showInSiteMap: false,
                      data: { showFooter: false },

                      loadChildren: () => [
                        {
                          path: `${COMPLEMENTARY_PLAYER_ROUTING.player}/:questionId`,
                          showInSiteMap: false,
                          loadComponent: () =>
                            import(
                              '@domains/money/complementary-player/complementary-player/complementary-player.component'
                            ).then((cmp) => cmp.ComplementaryPlayerComponent),
                          canActivate: [canViewDossierGuard, canEditDossierGuard, dossierCompletionGuard],
                        },

                        {
                          path: COMPLEMENTARY_PLAYER_ROUTING.player,
                          showInSiteMap: false,
                          loadComponent: () =>
                            import(
                              '@domains/money/complementary-player/complementary-player/complementary-player.component'
                            ).then((cmp) => cmp.ComplementaryPlayerComponent),
                          canActivate: [canViewDossierGuard, canEditDossierGuard, dossierCompletionGuard],
                        },

                        {
                          path: COMPLEMENTARY_PLAYER_ROUTING.validation,
                          showInSiteMap: false,
                          loadComponent: () =>
                            import(
                              '@domains/money/complementary-player/complementary-validation/complementary-validation.component'
                            ).then((cmp) => cmp.ComplementaryValidationComponent),
                          resolve: {
                            responses: moneyComplementaryResponsesResolver,
                          },
                          canActivate: [canViewDossierGuard, dossierValidationGuard],
                        },

                        {
                          path: '',
                          showInSiteMap: false,
                          redirectTo: COMPLEMENTARY_PLAYER_ROUTING.player,
                          pathMatch: 'full',
                        },
                      ],
                    },
                  ],
                },

                // DOSSIER UPDATE
                {
                  path: MONEY_ROUTING.dossierUpdate,
                  canActivate: [loadNextDossierToUpdateGuard],
                  showInSiteMap: false,

                  loadChildren: () => [
                    {
                      path: ':suggestionId/:questionId',
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/dossier-update/dossier-update-player/dossier-update-player.component'
                        ).then((cmp) => cmp.DossierUpdatePlayerComponent),
                      data: { showFooter: false },
                      canActivate: [canUpdateDossierGuard],
                    },
                    {
                      path: ':suggestionId',
                      showInSiteMap: false,
                      loadComponent: () =>
                        import('@domains/money/dossier-update/dossier-update.component').then(
                          (cmp) => cmp.DossierUpdateComponent
                        ),
                      canActivate: [canUpdateDossierGuard],
                    },
                  ],
                },
              ],
            },

            // TIPS
            {
              path: `${MONEY_DASHBOARD_ROUTING.tips}/${MONEY_ROUTING.articleSlug}`,
              title: MoneyRoutingTitlesEnum.Tips,
              showInSiteMap: false,
              canActivate: [
                requestServiceAuthorizationToRedirectGuard({
                  replaceUrl: false,
                  failedExitRouterLinkOrUrl: [
                    FEATURES_ROUTING.services,
                    SERVICES_ROUTING.money,
                    MONEY_DASHBOARD_ROUTING.tips,
                  ],
                }),
              ],
              loadComponent: () =>
                import('@domains/money/money-dashboard/tips/tip-view/tip-view.component').then(
                  (cmp) => cmp.TipViewComponent
                ),
              resolve: { moneyAdviceArticle: strapiBlogAdvicesResolver(environment.strapi.moneyApi) },
              data: {
                titlePath: 'moneyAdviceArticle.title',
                algoliaArticleIndex: environment.algolia.index.wizbiiMoneyAdvices,
              },
            },

            // HELPS
            {
              path: `${MONEY_DASHBOARD_ROUTING.helps}/${MONEY_DASHBOARD_ROUTING.articleSlug}`,
              showInSiteMap: false,
              title: MoneyRoutingTitlesEnum.Helps,
              canActivate: [
                hasStartedSimulationGuard,
                requestServiceAuthorizationToRedirectGuard({ replaceUrl: false }),
              ],
              loadComponent: () =>
                import('@domains/money/money-dashboard/help-view/help-view.component').then(
                  (cmp) => cmp.HelpViewComponent
                ),
              resolve: { helpArticle: strapiBlogHelpResolver(environment.strapi.moneyApi) },
              data: {
                titlePath: 'helpArticle.title',
                algoliaArticleIndex: environment.algolia.index.wizbiiMoneyHelps,
              },
            },
          ],
        },

        {
          path: MONEY_DASHBOARD_ROUTING.tabSlug,
          title: serviceTabTitleResolver,
          showInSiteMap: false,
          resolve: {
            content: TabContentFromSlugResolver,
          },
          loadComponent: async () =>
            (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
              .ServiceWithRichContentComponent,
        },
      ],
    },
  ],
};
