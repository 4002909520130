import { ObendyRoute } from '@core/app-routing';
import { hasNoBankingProductFeatureFlagGuard } from '@core/guards/feature-flag/has-no-banking-product-flag.guard';
import { hasLoginTokenGuard } from '@core/guards/has-login-token.guard';
import { platformHasUpsellGuard } from '@core/guards/has-upsell.guard';
import { isAlreadyTopPlanNoRenewGuard } from '@core/guards/is-already-top-plan-no-renew.guard';
import { isLoggedGuard } from '@core/guards/is-logged.guard';
import { platformHasPaymentGuard } from '@core/guards/platform-has-payment.guard';
import { shouldFinishOnboardingGuard } from '@core/guards/should-finish-onboarding.guard';
import { userIsAdultGuard } from '@core/guards/user-is-adult.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { isLegalGuardianMatcher } from '@features/upsell/guards/is-legal-guardian-matcher.guard';
import { isNotLegalGuardianMatcher } from '@features/upsell/guards/is-not-legal-guardian-matcher.guard';
import { isPaymentSuccessGuard } from '@features/upsell/guards/is-payment-success.guard';
import { redirectToPurchasePageGuard } from '@features/upsell/guards/redirect-to-purchase-page.guard';
import { adultArticleToPurchaseResolver } from '@features/upsell/resolvers/adult-basket.resolver';
import { UPSELL_ROUTING } from '@features/upsell/routes/upsell.routing';

export const UPSELL_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.upsell,
  title: 'Faire évoluer sa formule',
  canActivate: [
    hasLoginTokenGuard,
    isLoggedGuard,
    shouldFinishOnboardingGuard,
    platformHasUpsellGuard,
    isAlreadyTopPlanNoRenewGuard,
  ],
  showInSiteMap: false,

  loadChildren: () => [
    {
      path: '',
      loadComponent: async () => (await import('@features/upsell/upsell.component')).UpsellComponent,
      showInSiteMap: false,

      loadChildren: () => [
        {
          path: '',
          title: 'Faire évoluer sa formule',
          loadComponent: async () =>
            (await import('@features/upsell/offers/upsell-offers.component')).UpsellOffersComponent,
          showInSiteMap: false,
        },

        {
          path: UPSELL_ROUTING.bankingProducts,
          title: 'Nos offres formule supérieure',
          canActivate: [hasNoBankingProductFeatureFlagGuard, redirectToPurchasePageGuard, userIsAdultGuard],
          loadComponent: async () =>
            (await import('@features/upsell/banking-products/upsell-banking-products.component'))
              .UpsellBankingProductsComponent,
          showInSiteMap: false,
        },
      ],
    },

    {
      path: UPSELL_ROUTING.purchasePage,
      title: 'Panier',
      canActivate: [platformHasPaymentGuard, userIsAdultGuard],
      loadComponent: async () =>
        (await import('@features/upsell/purchase-page/purchase-page.component')).PurchasePageComponent,
      showInSiteMap: false,

      children: [
        {
          path: '',
          loadComponent: async () =>
            (await import('@features/upsell/adult-basket/adult-basket.component')).AdultBasketComponent,
          resolve: {
            articleToPurchase: adultArticleToPurchaseResolver,
          },
          canMatch: [isNotLegalGuardianMatcher],
          showInSiteMap: false,
        },

        {
          path: '',
          loadComponent: async () =>
            (await import('@features/upsell/guardian-basket/guardian-basket.component')).GuardianBasketComponent,
          canMatch: [isLegalGuardianMatcher],
          showInSiteMap: false,
        },
      ],
    },

    {
      path: UPSELL_ROUTING.purchaseSuccess,
      title: 'Félicitations',
      canActivate: [isPaymentSuccessGuard],
      loadComponent: async () =>
        (await import('@features/upsell/purchase-success/purchase-success.component')).PurchaseSuccessComponent,
      showInSiteMap: false,
    },
  ],
};
