import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { ApiTraining } from '@models/training/training';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TrainingWebservice {
  readonly #http = inject(HttpClient);

  get(id: string): Observable<ApiTraining> {
    return this.#http.get<ApiTraining>(`${environment.api.formation}/v1/formations/${id}`);
  }
}
