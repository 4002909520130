import { ObendyRoute } from '@core/app-routing';
import { ConnectedRoutesGuards } from '@core/guards/connected-routes.guard';
import { FEATURES_ROUTING } from '@features/features.routing';

export const COMMUNICATIONS_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.communications,
  showInSiteMap: true,
  title: 'Actualités',
  loadComponent: () =>
    import('@features/communications/communication-page/communication-page.component').then(
      (c) => c.CommunicationPageComponent
    ),
  canActivate: ConnectedRoutesGuards,
};
