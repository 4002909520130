import { inject } from '@angular/core';
import type { CanMatchFn } from '@angular/router';
import { EventFull, EventFullSourceEnum } from '@models/wizbii-event-full/wizbii-event-full.model';
import { Store } from '@ngrx/store';
import { selectEventFull } from '@stores/event-full/event-full.selectors';
import { filter, map } from 'rxjs';

export const isEventFullMatcherGuard: CanMatchFn = () => {
  return inject(Store)
    .select(selectEventFull)
    .pipe(
      filter((eventFull): eventFull is EventFull => !!eventFull),
      map((eventFull) => eventFull.source !== EventFullSourceEnum.WIZBII)
    );
};
