import { ObendyRoute } from '@core/app-routing';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { serviceIdResolver } from '@core/utils/service-id.resolver';
import { BENEFITS_ROUTING } from '@features/benefits/benefits.routing';
import { CanAccessBenefitReservation } from '@features/services/external-service/guards/can-access-benefit-reservation.guard';
import { CurrentTabIsGenericHtml } from '@features/services/external-service/guards/current-tab-is-html.guard';
import { CurrentTabIsGenericList } from '@features/services/external-service/guards/current-tab-is-list.guard';
import { ServiceWithHtml } from '@features/services/external-service/guards/service-with-html.guard';
import { ServiceWithList } from '@features/services/external-service/guards/service-with-list.guard';
import {
  RedirectToFirstTab,
  ServiceWithManyTabsMatchGuard,
} from '@features/services/external-service/guards/service-with-many-tabs.guard';
import { ServiceWithOneTabMatchGuard } from '@features/services/external-service/guards/service-with-one-tab.guard';
import { AuthorizationContextResolver } from '@features/services/external-service/resolvers/authorization-context.resolver';
import {
  BenefitSubscriptionFormResolver,
  BenefitSubscriptionPageResolver,
} from '@features/services/external-service/resolvers/benefit-subscription-page.resolver';
import { OneTabContentResolver } from '@features/services/external-service/resolvers/one-tab-content.resolver';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';

const RESERVATION_ROUTE: ObendyRoute = {
  path: `${BENEFITS_ROUTING.reservation}`,
  showInSiteMap: false,
  title: 'Réservation',
  canActivate: [CanAccessBenefitReservation, updateServiceConfigurationMiddleware],
  resolve: {
    benefitSubscriptionPage: BenefitSubscriptionPageResolver,
    benefitFormFields: BenefitSubscriptionFormResolver,
  },
  loadComponent: async () =>
    (await import('@features/benefits/reservation/reservation.component')).ReservationComponent,
};

const SERVICES_ONE_TAB_ROUTES: ObendyRoute[] = [
  {
    path: SERVICES_ROUTING.serviceId,
    showInSiteMap: false,
    title: serviceTitleResolver,
    canActivate: [updateServiceConfigurationMiddleware],
    canMatch: [ServiceWithOneTabMatchGuard, ServiceWithList],
    resolve: {
      serviceId: serviceIdResolver,
    },
    loadChildren: () => [
      {
        path: '',
        showInSiteMap: false,
        loadComponent: async () =>
          (await import('@features/services/external-service/service-with-articles/service-with-articles.component'))
            .ExternalServiceWithArticlesComponent,
      },
      RESERVATION_ROUTE,
    ],
  },
  {
    path: SERVICES_ROUTING.serviceId,
    showInSiteMap: false,
    canActivate: [updateServiceConfigurationMiddleware],
    canMatch: [ServiceWithOneTabMatchGuard, ServiceWithHtml],
    title: serviceTitleResolver,
    resolve: {
      serviceId: serviceIdResolver,
    },
    loadChildren: () => [
      {
        path: '',
        showInSiteMap: false,
        resolve: {
          content: OneTabContentResolver,
          authorizationContext: AuthorizationContextResolver(),
        },
        loadComponent: async () =>
          (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
            .ServiceWithRichContentComponent,
      },
      RESERVATION_ROUTE,
    ],
  },
];

export const SERVICES_WITH_MANY_TABS_ROUTES: ObendyRoute[] = [
  {
    path: `${SERVICES_ROUTING.serviceId}`,
    showInSiteMap: false,
    canActivate: [updateServiceConfigurationMiddleware],
    canMatch: [ServiceWithManyTabsMatchGuard],
    title: serviceTitleResolver,
    resolve: {
      serviceId: serviceIdResolver,
    },
    loadChildren: () => [
      {
        path: '',
        showInSiteMap: false,
        canActivate: [RedirectToFirstTab],
        children: [],
      },
      RESERVATION_ROUTE,
      {
        path: ':tabSlug',
        title: serviceTabTitleResolver,
        showInSiteMap: false,
        canMatch: [CurrentTabIsGenericHtml],
        resolve: {
          content: TabContentFromSlugResolver,
          authorizationContext: AuthorizationContextResolver(),
        },
        loadComponent: async () =>
          (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
            .ServiceWithRichContentComponent,
      },
      {
        path: ':tabSlug',
        title: serviceTabTitleResolver,
        showInSiteMap: false,
        canMatch: [CurrentTabIsGenericList],
        resolve: {
          content: TabContentFromSlugResolver,
          authorizationContext: AuthorizationContextResolver(),
        },
        loadComponent: async () =>
          (await import('@features/services/external-service/service-with-articles/service-with-articles.component'))
            .ExternalServiceWithArticlesComponent,
      },
    ],
  },
];

export const EXTERNAL_SERVICES_ROUTES: ObendyRoute[] = [...SERVICES_ONE_TAB_ROUTES, ...SERVICES_WITH_MANY_TABS_ROUTES];
