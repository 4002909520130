import { ObendyRoute } from '@core/app-routing';
import { hasLoginTokenGuard } from '@core/guards/has-login-token.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { faqResolver } from '@features/resolvers/faq.resolver';

export const CONTACT_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.contact,
  title: 'Contact',
  canActivate: [hasLoginTokenGuard],
  loadComponent: () => import('@features/contact/contact/contact.component').then((cmp) => cmp.ContactComponent),
  data: { shouldReuse: true },
  resolve: { FAQBlock: faqResolver(FEATURES_ROUTING.contact) },
  showInSiteMap: true,
};
