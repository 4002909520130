import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { service } from '@stores/services/services.selector';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { map, take } from 'rxjs';

export const AssessFirstTabRedirectGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);
  const tabSlug = route.params.tabSlug;
  const serviceId = SERVICES_ROUTING.assessFirst;

  return selectOnceIfDefined(store, service(serviceId)).pipe(
    take(1),
    map((service) => {
      return tabSlug
        ? true
        : router.parseUrl(`/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.assessFirst}/${service.tabs[0].slug}`);
    })
  );
};
