import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { isServiceUnavailableInUserPlan } from '@stores/selectors/profile-services.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { map, take } from 'rxjs/operators';

export const hasAccessToMoney: CanActivateFn = () => {
  const store = inject(Store);

  return selectIfDefined(store, isServiceUnavailableInUserPlan(SERVICES_ROUTING.money)).pipe(
    map((hasNotAccess) => !hasNotAccess),
    take(1)
  );
};

export const hasNoAccessToMoney: CanActivateFn = () => {
  const store = inject(Store);

  return selectIfDefined(store, isServiceUnavailableInUserPlan(SERVICES_ROUTING.money)).pipe(map(Boolean), take(1));
};
