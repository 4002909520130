import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { ASSESS_FIRST_ROUTING } from '@features/services/assess-first/assess-first.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { isServiceAuthorized } from '@stores/profile/profile.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { map } from 'rxjs';

export const isAssessFirstActivated: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return selectOnceIfDefined(store, isServiceAuthorized(SERVICES_ROUTING.assessFirst)).pipe(
    map((isServiceActivated) => {
      return isServiceActivated
        ? true
        : router.parseUrl(
            `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.assessFirst}/${ASSESS_FIRST_ROUTING.personalityTest}`
          );
    })
  );
};
