import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { isLegalGuardian } from '@stores/profile/profile.selectors';
import { Observable, filter, map } from 'rxjs';

export function isNotLegalGuardianMatcher(): Observable<boolean> {
  return inject(Store)
    .select(isLegalGuardian)
    .pipe(
      filter((isLegalGuardian): isLegalGuardian is boolean => typeof isLegalGuardian === 'boolean'),
      map((isLegalGuardian) => !isLegalGuardian)
    );
}
